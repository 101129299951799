import { useState, useEffect, Fragment, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LazyLoadComponent = props => {
	const { image, title, alt, blur = '', className = '', containVideoOverlay = false } = props;
	const [loaded, setLoaded] = useState(false);
	const [windowLoaded, setWindowLoaded] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined") {
			if (!windowLoaded) {
				setWindowLoaded(true);
			}
		}
	}, []);

	const onLoad = useCallback(() => {
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (windowLoaded) {
			// Preload IMG
			const img = new Image();

			img.src = "";
			img.addEventListener('load', onLoad, false)
			img.src = image.url;

			return () => img.removeEventListener('load', onLoad);
		}
	}, [windowLoaded])

	return (
		<div className={`lazyLoad ${className}`}>
			{typeof image.url !== 'undefined' && (
				<Fragment>
					<img src={image.url} alt={alt} title={title ? title : alt} className={`lazyLoadImg ${loaded ? 'loaded' : 'loading'}`} width={image.data.width} height={image.data.height} />
					{containVideoOverlay && (
						<div className="video__overlay">
							<FontAwesomeIcon
								icon={["far", "play-circle"]}
								size="1x"
							/>
						</div>
					)}
					{!loaded &&
						<div className={`placeholder ${loaded ? 'loaded' : 'loading'} ${blur}`}>
							<img
								src={image.lazyLoad}
								width={image.data.width}
								height={image.data.height}
								alt={title ? title : "Honda South Africa"}
							/>
						</div>
					}
				</Fragment>
			)}
		</div>
	);
};

export default LazyLoadComponent;